@import "euphoria/buttons";
@import "euphoria/responsive/breakpoints";
@import "euphoria/utils";
@include normalize;

$column-gutter-bottom: 1em;
$element-vertical-spacing: 1em;
$layout-width: 1170px;

@import "partials/functions";
@import "../../config/config";
@import "partials/typography";
@import "partials/layout";
@import "partials/fragments";
@import "partials/templates";

body {
	@extend %body-font;
	@extend %body;
}

.wf-loading {
	h1, h2, h3, h4, h5, h6, p, li {
		visibility: hidden;
	}
}

.content {
	margin: 0 auto;
	max-width: 1170px;
	padding: 0 1em;
}

.fg-element {
	margin: $element-vertical-spacing 0;

	.body & {
		min-height: 1em;
	}

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}
}

#google_map img {
	max-width: none !important;
}

$fancybox-image-url: '../../images/fancybox';
@import "vendor/jquery.fancybox";
